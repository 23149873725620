<template>
<div ref="messageWindow">
    <simplebar data-simplebar ref="messageLogPS" style="height: calc(100vh - 230px);">
        <ul style="min-height: calc(100vh - 330px);" class="conversation-list chat-app-conversation" ref="messageLog">
            <li class="clearfix" v-for="(message, index) in messages" :key="index">
                <div class="odd" v-if="isSentUser(message.who, message.sender_id)">
                    <message-content @showImg="showImg($event)" :message="message"  float="right" >
                        <template v-slot:footer-action>
                             <a class="text-success mr-2" @click.prevent="initUpdate(message)" href="#">Edit</a>
                             <a class="text-danger" @click.prevent="deleteMessage(message.id)" href="#">Delete</a>
                        </template>
                    </message-content>
                </div>
                <div v-else >
                    <message-content @showImg="showImg($event)" :message="message" float="left">
                        <template v-slot:footer-action>
                             <a class="text-success" @click.prevent="initReply(message.id)" href="#">Reply</a>
                        </template>
                    </message-content>
                </div>
            </li>
        </ul>
    </simplebar>
    <slot name="footer"></slot>
    <b-modal centered scrollable id="modal-1" size="sm" v-model="showUpdateModal" :title="isUpdateMode ? 'Update Message' : 'Reply Message'" header-close-variant="primary" title-class="font-18 text-primary" hide-footer>
        <div class="row justify-content-end">
            <div class="col-12 d-flex align-items-center">
                <div class="form-group w-100">
                    <textarea  v-model="onUpdate.message" class="form-control" rows="3" placeholder="Enter something..."></textarea>
                </div>
            </div>
            <div class="col-12">
                <div class="text-center">
                    <button type="button" @click.prevent="isUpdateMode ? updateMessage() : replyMessage()" class="btn btn-primary mt-2">
                    <i class="mdi mdi-content-save"></i> {{isUpdateMode ? 'Update' : 'Reply'}}  </button>
                </div>
            </div>
        </div>
    </b-modal>
    <vue-easy-lightbox :visible="visible" :imgs="lightboxFiles" :index="lightboxIndex" @hide="handleHide"></vue-easy-lightbox>
</div>
</template>

<script>

import simplebar from "simplebar-vue";
import MessageContent from './MessageContent';
import VueEasyLightbox from "vue-easy-lightbox";

export default {
    components:{
        simplebar,
        MessageContent,
        VueEasyLightbox
    },
    props:{
        messages:{
            type:Array,
            required:true
        }
    },
    data(){
        return {
            visible: false,
            lightboxIndex: null,
            onUpdate : {
                message : "",
            },
            isUpdateMode : true,
            showUpdateModal : false,
            shownFiles:[]
        }
    },
    computed: {
        activeUser() {
            return this.$store.state.activeUser
        },
        buyer(){
            return this.$store.state.orderList.order.buyer
        },
        isSentUser(){
            return (who, senderId) => who == 'buyer' || senderId == this.activeUser.id;
        },
        lightboxFiles(){
            return this.shownFiles.map(file => {
                if(file.is_video){
                  return {
                      title: file.name,
                      thumb: this.$store.state.domain + '/'+file.path,
                      mediaType: 'video',
                      src: this.$store.state.domain + '/'+file.video_path }
                }else{
                  return {
                      title: file.name,
                      mediaType: 'image',
                      src: this.$store.state.domain +  '/'+file.path }
                }
            })
        },
    },
    methods: {
        scrollMessageAreaUp(){
            setTimeout(()=>{
                const scrollH = this.$refs.messageLog.scrollHeight
                const scrolEl = this.$refs.messageLogPS.SimpleBar.getScrollElement();
                // scrolEl.style.backgroundColor = "red";
                // scrolEl.style.overflowY = "auto";
                // scrolEl.style.scrollBehavior = "smooth";

                // $(scrolEl).scrollTop(scrollH);
                // this.$refs.messageLogPS.SimpleBar.getScrollElement().scrollTo(0, scrollH);
                scrolEl.scrollTop = scrollH;
                // console.dir(scrolEl);

            },200)
        },
        initUpdate(msg){
            this.isUpdateMode = true;
            this.onUpdate = {
                message: msg.message,
                id: msg.id,
            }
            this.showUpdateModal = true

        },
        initReply(msgId){
            this.isUpdateMode = false;
            this.onUpdate = {
                message: "",
                id: msgId,
            }
            this.showUpdateModal = true

        },
        updateMessage() {
            if (!this.onUpdate.message) { this.alertError("Please type something"); return;}
            const formData = new FormData()
            formData.append('data', JSON.stringify(this.onUpdate))
            this.requestProcessor(formData, `${this.onUpdate.id}/update`, "UPDATE")
        },
        replyMessage() {
            if (!this.onUpdate.message) { this.alertError("Please type something"); return;}
            const formData = new FormData()
            formData.append('data', JSON.stringify({'reply': this.onUpdate.message}))
            this.requestProcessor(formData, `${this.onUpdate.id}/reply`, "UPDATE")
        },
        requestProcessor(formData, url, action='ADD'){
            this.$store.dispatch("changeLoaderValue", true)
            this.$http.post(`/order-messages/${url}`,formData)
            .then((response) => {
                this.$store.dispatch("changeLoaderValue", false)
                if(response.data.success){
                    this.alertSuccess(response.data.success)
                    this.$store.commit(`orderList/${action}_MESSAGE`,response.data.message)
                    this.resetForm();
                }else if(response.data.errors){
                    response.data.errors.forEach((error) => { this.alertError(error)})
                }else{
                    this.alertError(response.data.error)
                }
            }).catch((error)=> {console.log(error)  })
        },
        deleteMessage(itemId){
            if(!itemId){return}
            Swal.fire({
                title: "Are you sure?",
                text: "You are about to delete this message!",
                reverseButtons:true,
                showCancelButton: true,
                confirmButtonColor: "#e11",
                cancelButtonColor: "#111",
                confirmButtonText: "Delete it!",
            }).then((result) => {
                if (result.value) {
                    this.$store.dispatch("changeLoaderValue", true)
                    this.$http.delete(`/order-messages/${itemId}/delete`)
                    .then((response) => {
                        this.$store.dispatch("changeLoaderValue", false)
                        if(response.data.success){
                            this.alertSuccess(response.data.success)
                            this.$store.commit("orderList/DELETE_MESSAGE", response.data.messageId)
                        }else{
                            this.alertError(response.data.error)
                        }
                    }).catch((error) => { console.log(error);})
                }
            });
        },
        resetForm(){
            this.onUpdate = {
                message: ""
            }
            this.showUpdateModal = false
            this.isUpdateMode = true;
        },
        showImg(payload) {
            console.log(payload)

            this.shownFiles = [...payload.files]
            this.lightboxIndex = payload.index;
            this.visible = true;
        },
        handleHide() {
            this.visible = false;
            this.shownFiles = []
        },
    }

};
</script>

<style lang="scss">
.simplebar-placeholder{
    display: none !important;
}
.conversation-list {
    list-style: none;
    padding: 0 15px;
    li {
        margin-bottom: 20px;

        .conversation-actions {
            float: right;
            display: none;
        }

        &:hover {
            .conversation-actions {
                display: block;
            }
        }

        .conversation-lists{
            display: inline-block;
            position: relative;
        }
    }
    .border-bottom{
        border-color: #fff !important;
    }
    .chat-avatar {
        float: left;
        text-align: center;
        width: 42px;
        img {
            border-radius: 100%;
            width: 100%;
        }
        i {
            font-size: 12px;
            font-style: normal;
        }
    }
    .ctext-wrap {
        background: #ebedef;
        border-radius: 3px;
        display: inline-block;
        padding: 12px;
        position: relative;
        i {
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            position: relative;
        }
        p {
            margin: 0;
            padding-top: 3px;
        }
        &:after {
            left: -10px;
            top: 0;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-top-color: #ebedef;
            border-width: 6px;
            margin-right: -1px;
            border-right-color: #ebedef;
        }
    }
    .conversation-text {
        float: left;
        font-size: 12px;
        margin-left: 12px;
        width: 80%;
    }
    .odd {
        color: #008081;
        a,p{
         color: #008081;
        }
        .chat-avatar {
            float: right !important;
        }
        .conversation-text {
            float: right !important;
            margin-right: 12px;
            text-align: right;
            width: 80%;
        }
        .ctext-wrap {
            background-color: #e7f2f4;
            &:after {
                border-color: transparent;
                border-left-color: #e7f2f4;
                border-top-color: #e7f2f4;
                right: -10px !important;
                left: auto;
            }
        }
        .conversation-actions {
            float: left;
        }
    }
}
</style>