<template>
  <div class="order-brief py-4">
      <addons />
      <hr>
      <requirements />
  </div>
</template>

<script>
import Requirements from "./components/Requirements.vue"
import Addons from "./components/Addons.vue"

export default {
  components:{
      Addons,
    Requirements
  }
}

</script>

