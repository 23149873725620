<template>
  <div v-if="addons && addons.length" class="">
    <h3 class="font-weight-bold">Extra Services</h3>
    <div class="justify-center items-center">
        <div class="mb-4">
            <ul class="list">
                <template v-for="(addon, adIndex) in addons">
                    <li :key="adIndex"
                    class="list__item d-block">
                        <h5 class="mb-0 font-weight-bold" ><span class="">{{adIndex+1}}.)</span> {{addon.name}} (${{addon.charge | money_format}})</h5>
                        <div class="py-2">
                            {{addon.description}}
                        </div>
                    </li>
                </template>
            </ul>
        </div>
    </div>
</div>
</template>

<script>
export default {
  computed: {
    order(){
      return this.$store.state.orderList.order
    },
    addons(){
        return this.order.cart.cart.addons
    },
  }
}
</script>
