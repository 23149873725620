<template>
  <div class="">
    <h3 class="font-weight-bold">Order Requirements</h3>
    <div class="">
        <div class="mb-4">
            <ul class="list">
                <template v-for="(requiredField, reIndex) in requirements">
                    <li :key="reIndex"
                         class="list__item d-block">
                        <h4 class="d-flex mb-1"><span class="text-success mr-1">➳</span> {{requiredField.question}}</h4>
                        <div class="pl-5 pt-3" v-if="requiredField.key == 'logo_type'">
                          <img class="shadow" :src="`${$store.state.domain}/${logoType.path}`" :alt="logoType.name" width="100" /> 
                          <div class="color-type-name py-2">
                            <h6 class="mb-0">{{logoType.name}}</h6>
                          </div>
                        </div>
                        <div class="pl-4" v-else-if="requiredField.key == 'color_types'">
                          <div class="row m-0 color-types">
                            <div v-for="(colorType, clIndex) in colorTypes" :key="clIndex"
                              class="col-md-3 col-sm-6 col-lg-2 p-2">
                                <div class="color-type shadow bg-white">
                                    <div class="row g-0">
                                        <div v-for="(swatch, sIndex) in 9" :key="sIndex"
                                          :style="{'background-color': colorType.colors[sIndex]}"
                                          class="col-4 p-0 color-type-block">
                                        </div>
                                    </div>
                                    <div class="color-type-name py-2">
                                        <h6 class="mb-0 text-center">{{colorType.name}}</h6>
                                    </div>
                                </div>
                            </div>
                          </div>
                        </div>
                        <div v-else class="pl-5 pb-3" style="color:#aaa;">
                          <p class="mb-0">{{requiredField.answer}}</p>
                        </div>
                    </li>
                </template>
            </ul>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <h3 class="font-weight-bold">Attached Files</h3>
        </div>
        <div class="col-sm-6 co-md-3 col-lg-3 mb-4" v-for="(file, index) in files" :key="index">
          <a :href="`${$store.state.domain}/download?file_path=${file.path}`"
            :download="file.name" style="color:inherit;" class="downloadable">
            <div>
              <h6 class="mb-0">{{file.name}}</h6>
              <span class="mr-2 "> size: {{file.size}}, ext.: {{file.extension}} </span>
            </div>
          </a>
        </div>
        <p v-if="!files.length"><em>Attached files show here</em></p>
    </div>
</div>
</template>

<script>
export default {
  computed: {
    order(){
      return this.$store.state.orderList.order
    },
    requirements(){
      return this.order.cart.cart.requirements
    },
    logoType(){
      return this.order.cart.cart.logoType
    },
    colorTypes(){
      return this.order.cart.cart.colorTypes
    },
    files(){
      return this.order.sample_files
    },
  }
}
</script>

<style scoped>
.color-types .color-type-block{
    height: 45px;
}

@media(max-width:567px){
    .color-types .color-type-block{
        height: 40px;
    }
}
</style>