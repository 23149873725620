<template>
  <div id="order-view" class="order-show py-5 py-lg-6" >
    <div class="mb-3">
      <button @click="$router.go(-1)" type="button" class="btn btn-light"> <i class="mdi mdi-arrow-left"></i> Back</button> 
    </div>
    <div v-if="isLoading">
        <div class="mb-3">
            <b-skeleton animation="wave" height="50px" width="70%"></b-skeleton>
        </div>
        <div class="mb-3">
            <b-skeleton animation="wave" height="50px" width="80%"></b-skeleton>
        </div>
        <div class="mb-5">
            <b-skeleton animation="wave" height="40px" width="50%"></b-skeleton>
        </div>
        <div >
            <b-skeleton animation="wave" height="400px" width="100%"></b-skeleton>
        </div>
    </div>
    <section v-else class="">
        <div class="row">
            <div class="col-lg-12">
                <div v-if="order_not_found">
                    <b-alert show variant="danger">
                        <h5 class="alert-heading"><i class="mdi mdi-block-helper mr-2"></i>Order Not Found!</h5>
                        <hr class="my-1">
                        <p class="mb-0">
                        <span class="mr-2">Order record with id: {{ $route.params.orderId }} not found. </span>
                        <button @click="$router.push('/orders').catch(() => {})" class="btn btn-outline-danger">Return to Orders</button>
                        </p>
                    </b-alert>
                </div>
                <div v-else>
                    <div class="row">
                        <div class="col-12">
                          <div v-if="order.status == 'draft'">
                            <div class="alert alert-warning" role="alert">
                              <h4 class="alert-heading">Saved as draft!</h4>
                              <p class="m-0">This order was saved as draft. You can proceed to make adjustment (if any) for the order to be processed. <a class="alert-link" :href="`${$store.state.domain}/projects/${order.id}/pricing`"> Click to continue</a></p>
                            </div>
                          </div>
                          <div v-else>
                            <div class="mb-4">
                              <h2 class="mb-2 font-weight-bold">{{product.name}} - #LP0{{order.id}}</h2>
                              <p>Project was placed by <strong>{{activeUser.first_name}}.</strong> <span>Project ID: <strong>#LP0{{order.id}}</strong></span> <a class="text-success" :href="`${$store.state.domain}`">view product</a></p>
                            </div>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="card">
                            <div class="card-body px-4 px-sm-5">
                              <b-tabs nav-class="nav-tabs order-show-nav nav-bordered">
                                <b-tab title="Brief" :active="activeTab === 1">
                                    <order-brief />
                                </b-tab>
                                <b-tab title="Messages" :active="activeTab === 2">
                                    <order-messages />
                                </b-tab>
                              </b-tabs>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  </div>
</template>

<script>
import OrderBrief from "./OrderBrief.vue"
import OrderMessages from "./OrderMessages.vue"

export default {
  name: "order-show",
  components: {
    OrderBrief,
    OrderMessages,
  },
  data() {
    return {
      isLoading: true,
      order_not_found: false
    }
  },
  watch: {
    "$route":"fetchOrder"
  },
  computed: {
    order(){
      return this.$store.state.orderList.order
    },
    activeUser(){
      return this.$store.state.activeUser
    },
    product(){
      return this.order.cart.cart.product
    },
    activeTab(){
      return this.$route.query.tab ? +this.$route.query.tab : 2;
    },
  },
  methods:{
    markAsCompleted() {
       Swal.fire({
        title: "Are you sure?",
        text: "You are about to update this order to completed.",
        reverseButtons:true,
        showCancelButton: true,
        confirmButtonColor: "#1abc9c",
        cancelButtonColor: "#111",
        confirmButtonText: "Proceed",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.post(`/orders/${this.order.id}/mark-completed`)
          .then(response => {
            this.$store.dispatch("changeLoaderValue",false)
            if(response.data.success){
              this.$store.dispatch("orderList/fetchOrder",this.order.id)
              this.showReviewModal = true
              this.alertSuccess(response.data.success)
            }else{
              this.alertError(response.data.error)
            }
          })
          .catch(error => { console.log(error) })
        }
      });
    },
    fetchOrder(){
      this.isLoading = true
      this.$store.dispatch("orderList/fetchOrder",this.$route.params.orderId)
      .then((response) => {
        this.isLoading = false
         if(!response.data.success){
          this.order_not_found = true
          this.alertError(response.data.error)
        }
      }).catch((error) => { console.log(error); this.order_not_found = true })
    },
  },
  created(){
    this.fetchOrder()
  },

}
</script>

<style lang="scss">
#profile-tabs {
  .vs-tabs--content {
    padding: 0;
  }
}
.order-show .soft-bread{
    background: #aaaaaa
}

.order-show .soft-bread .soft-bread-item{
    display:inline-block;
    color:white;
    padding: 0.2rem 0.8rem;
}
.order-show-nav .nav-link{
    color: #000000 !important;
    font-size:20px;
    padding:0.1rem 0rem;
    padding-bottom: 0.5rem;
    margin-right:2rem;
    font-weight:bold;
    border: 0px solid transparent;
}
.order-show-nav .nav-link.active{
    background-color: transparent;
    border-bottom: 2px solid #3bb97f;
}
</style>
