<template>
<div class="conversation-text" :class="float === 'right' ? 'ms-0':'me-0'">
    <div class="ctext-wrap">
        <p class="mb-2 font-16">{{ message.message }}</p>
        <message-files v-if="message.files && message.files.length"
         :files="message.files" @showImg="showImg($event)"/>
        <p class="mb-2 pb-2 border-bottom"> <small style='font-size:10px;'>{{ message.created_at | date_ago }}</small></p>
        <div v-if="message.replyer_id" class="mb-2 py-2 text-right">
            <h6 class="font-weight-bold font-italic text-inherit text-end">{{message.who == 'buyer'? 'Admin' : 'You'}} Replied:</h6>
            <p class="mb-2 text-right">{{message.reply}}</p>
            <small style='font-size:10px;'>{{ message.replied_at | date_ago }}</small>
        </div>
        <slot v-else name="footer-action"></slot>
    </div>
</div>
</template>

<script>
import MessageFiles from "./MessageFiles.vue"

export default {
    components:{
        MessageFiles
    },
    props:{
        message:{
            type:Object,
            required:true
        },
        float:{
            type: String,
            required:true
        }
    },
    methods:{
        showImg(payload){
            this.$emit("showImg", payload)
        }
    }

};
</script>


<style>

</style>
