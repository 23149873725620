<template>
    <div class="order-messages">
        <div class="card shadow-sm">
            <div class="card-body p-2">
                <message-window ref="orderLogPSWindow" :messages="messageData">
                    <template v-slot:footer>
                        <div class="row">
                            <div class="col">
                                <div class="mt-2 p-0 rounded">
                                    <form class="needs-validation" @submit.prevent="sendMessage" name="chat-form" id="chat-form">
                                        <b-input-group>
                                            <b-input-group-append>
                                                <button @click="showUploadFileModal = true" type="button" class="btn rounded-left btn-secondary"><i class="mdi mdi-cloud-upload"></i></button>
                                            </b-input-group-append>
                                            <b-form-input v-model="form.message" placeholder="Send us message"></b-form-input>
                                            <b-input-group-append>
                                                <button type="submit" class="btn btn-primary chat-send btn-block">
                                                        <i class="fas fa-paper-plane"></i>
                                                </button>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </template>
                </message-window>
            </div>
        </div>
        <div>
            <b-modal centered id="modal-2" v-model="showUploadFileModal" title="Upload Files" header-close-variant="primary" title-class="font-18 text-primary" hide-footer>
                <upload-attachment @scrollMessagesUp="resetForm(); scrollMessageAreaUp() "/>
            </b-modal>
        </div>
    </div>
</template>

<script>

import MessageWindow from "./components/MessageWindow.vue"
import UploadAttachment from "./components/UploadAttachment.vue"

export default {
    name: 'order-message',
	components: {
        MessageWindow, UploadAttachment
	},
	data() {
		return {
            showUploadFileModal:false,
            form:{
                message: ""
            },
		}
	},
	computed: {
        order(){
            return this.$store.state.orderList.order
        },
        messageData(){
            return this.$store.getters['orderList/getMessages']
        },
        searchQuery: {
            get() {
                return this.$store.state.orderList.messageSearchQuery
            },
            set(val) {
                this.$store.commit('orderList/SET_MESSAGE_SEARCH_QUERY', val)
            }
        },
	},
	methods: {
        sendMessage() {
            if (!this.form.message) { this.alertError("Please type something"); return;}
            const formData = new FormData()
            formData.append('data', JSON.stringify(this.form))
            this.requestProcessor(formData, `${this.order.id}/create`)
        },
        requestProcessor(formData, url, action='ADD'){
            this.$store.dispatch("changeLoaderValue", true)
            this.$http.post(`/order-messages/${url}`,formData)
            .then((response) => {
                if(response.data.success){
                    this.$store.dispatch("changeLoaderValue", false)
                    this.alertSuccess(response.data.success)
                    this.$store.commit(`orderList/${action}_MESSAGE`,response.data.message)
                    this.resetForm();
                    this.scrollMessageAreaUp();
                }else if(response.data.errors){
                    response.data.errors.forEach((error) => { this.alertError(error)})
                }else{
                    this.alertError(response.data.error)
                }
            }).catch((error)=> {console.log(error)  })
        },
        scrollMessageAreaUp(){
            setTimeout(()=>{
                this.$refs.orderLogPSWindow.scrollMessageAreaUp()
            },1000)
        },
        resetForm(){
            this.showUploadFileModal = false;
            this.form.message =  "";
        },
	},
    mounted(){
        this.scrollMessageAreaUp()
    },

}
</script>

