<template>
    <div class="message-files row px-2">
        <div class="col-sm-6 g-2" v-for="(file, fIndex) in files" :key="fIndex">
            <div class="card my-0 shadow-none border text-left">
                <a href="javascript:void(0)" @click="() => showImg(fIndex)"
                    style="color:inherit;" class="font-sm">
                    <div class="mb-1" v-if="isImageFile(file.extension)">
                        <img class="img-fluid" :src="`${$store.state.domain}/${file.path}`" :alt="file.name">
                    </div>
                    <div class="p-2">
                        <div class="row align-items-center">
                            <div class="col-auto pr-0">
                                <span class="badge badge-primary font-10 p-1 rounded text-uppercase">{{file.extension}}</span>
                            </div>
                            <div class="col">
                                <a :href="`${$store.state.domain}/download?file_path=${file.path}`" class="">
                                    <span class="d-none d-sm-inline text-muted">{{file.name}}</span>
                                    <p class="mb-0 d-flex font-sm"><i class="mdi mdi-download"></i>{{file.size}}</p>
                                </a>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "message-files",
    props: {
        files: {
            type: Array,
            required: true,
        },
    },
    computed: {
        isImageFile(){
            return (ext) => (ext === 'jpeg' || ext === 'webp' || ext === 'png' || ext === 'jpg' || ext === 'svg')
        },
    },
    methods:{
        showImg(imgIndex){
            this.$emit("showImg", {files: this.files, index: imgIndex})
        }
    }
}
</script>
<style scoped>


</style>